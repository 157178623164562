import Vue from "vue";
import Dialog from "@/store/modules/nuevoKioskoModule";
import {
  mdiCashPlus,
  mdiPlus,
  mdiCancel,
  mdiCurrencyUsd,
  mdiCash,
  mdiShieldAccount,
  mdiMonitorDashboard,
  mdiHomeMapMarker,
  mdiGoogleMaps,
} from "@mdi/js";
import { Municipio, Estado } from "apd.geografia.models";
import {
  Kiosko,
  Empresa,
  DispositivoMoneda,
  DispositivoBillete,
  KioskoConfiguracion,
  KioskoMacAddress,
  /*KioskoServicioPermiso,
  KioskoServicioSucursal,
  KioskoFormaPagoServicio,
  KioskoEstatusServicioImagen,*/
  KioskoDispensador,
  TipoKiosko,
  TipoDeposito,
  Servicio,
  EstatusServicio,
  FormaPago,
  Banco,
  Sucursal,
} from "apd.sistemapagos.models";
import { Guid } from "guid-typescript";
import { formatISO } from "date-fns";
import CommonDataModule from "@/store/modules/commonDataModule";
import CajerosModule from "@/store/modules/cajerosModule";
import { AxiosHttpClient } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import { Banorte } from "apd.models";

export default Vue.extend({
  name: "NuevoKiosko",
  data() {
    return {
      Icons: {
        Dotaciones: mdiCashPlus,
        Agregar: mdiPlus,
        Eliminar: mdiCancel,
        Cantidad: mdiCashPlus,
        Denominacion: mdiCurrencyUsd,
        TipoDenominacion: mdiCash,
        Custodio: mdiShieldAccount,
        Cajero: mdiMonitorDashboard,
        Empresa: mdiHomeMapMarker,
        Ubicacion: mdiGoogleMaps,
      } as Record<string, string>,
      cajeros: [] as Kiosko[],
      e1: 1,
      municipios: [] as Municipio[],
      servicios: [] as Servicio[],
      bancos: [] as Banco[],
      afiliacion: [] as Banorte.Payworks2.Afiliacion[],
      servicios2: {} as Servicio,
      formaPago: [] as FormaPago[],
      idEstado: 0 as number,
      idSer: "" as string,
      principal: false as boolean,
      top: false as boolean,
      tabs: ["Información General", "Efectivo", "Servicios", "Configuraciones", "Imagenes", "Formas de Pago"],
      kiosko: {} as Kiosko,
      tiposKiosko: ["Virtual", "Fisico"],
      tiposDeposito: ["Interno", "Externo", "Virtual"],
      estatusServicio: ["Inactivo", "Suspendido", "Mantenimiento", "Activo"],
      valueEst: 0 as number,
      empresa: [] as Empresa[],
      sucursal: [] as Sucursal[],
      idEmp: "",
      dispositivoMoneda: [] as DispositivoMoneda[],
      dispositivoBillete: [] as DispositivoBillete[],
      configuraciones: [] as KioskoConfiguracion[],
      macAddresses: [] as KioskoMacAddress[],
      /*servicioPermisos: [] as KioskoServicioPermiso[],
      servicioSucursales: [] as KioskoServicioSucursal[],
      formaPagoServicios: [] as KioskoFormaPagoServicio[],
      servicioImagenes: [] as KioskoEstatusServicioImagen[],*/
      dispensadores: [] as KioskoDispensador[],
      isFormValid: false,
      rules: {
        required: (value: string) => !!value || "Required.",
      },
      tiposDispensadores: [
        { nombreP: "Disp1", denominacion: "Moneda", denom: 1, puerto: "COM1", nodo: "NODO4", min: 0, lim: 0 },
        { nombreP: "Disp2", denominacion: "Moneda", denom: 1, puerto: "COM1", nodo: "NODO5", min: 0, lim: 0 },
        { nombreP: "Disp3", denominacion: "Moneda", denom: 1, puerto: "COM1", nodo: "NODO7", min: 0, lim: 0 },
        { nombreP: "Disp4", denominacion: "Billete", denom: 0, puerto: "COM7", nodo: "Bandeja2", min: 0, lim: 0 },
        { nombreP: "Disp1", denominacion: "Billete", denom: 0, puerto: "COM7", nodo: "Bandeja1", min: 0, lim: 0 },
      ],
      headersDisp: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombreP",
        },
        { text: "Puerto", align: "start", sortable: false, value: "puerto" },
        { text: "Nodo", align: "start", sortable: false, value: "nodo" },
        { text: "Denominacion", align: "start", sortable: false, value: "denominacion" },
        { text: "Minimo", align: "start", sortable: false, value: "min" },
        { text: "Limite", align: "start", sortable: false, value: "lim" },
      ],
      singleSelect: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selected: [] as any[],
      tiposPropiedad: [
        { propiedad: "BaseDatosLocal", valor: "" },
        { propiedad: "Conciliable", valor: 0 },
        { propiedad: "LongitudCorteCaja", valor: 0 },
        { propiedad: "LongitudDotacion ", valor: 0 },
        { propiedad: "LongitudOperacion ", valor: 0 },
        { propiedad: "ManejaActas", valor: false },
        { propiedad: "MultipleCorteCajaDia", valor: false },
        { propiedad: "TipoImpresora", valor: "" },
        { propiedad: "VaciadoObligatorio ", valor: false },
        { propiedad: "ValidarIngresoBillete ", valor: false },
      ],
      headers: [
        {
          text: "Servicio",
          align: "start",
          sortable: false,
          value: "idServicio",
        },
        {
          text: "Estatus",
          align: "start",
          sortable: false,
          value: "idEstatusServicio",
        },
        {
          text: "URL",
          align: "start",
          sortable: false,
          value: "urlImagen",
        },
      ],
    };
  },
  computed: {
    dialog: {
      get() {
        return Dialog.dialog;
      },
      set() {
        Dialog.Hide();
      },
    },
    idKiosko: function () {
      return Guid.create().toString();
    },
    empresasList(): Empresa[] {
      return CommonDataModule.Empresas;
    },
    dispositivoMonedaList(): DispositivoMoneda[] {
      return CommonDataModule.DispositivoMoneda;
    },
    dispositivoBilleteList(): DispositivoBillete[] {
      return CommonDataModule.DispositivoBillete;
    },
    estadosList(): Estado[] {
      return CajerosModule.estados;
    },
    municipiosList(): Municipio[] {
      return CajerosModule.municipios;
    },
    serviciosList(): Servicio[] {
      return CommonDataModule.Servicios;
    },
    tipoKioskos() {
      return (value: number): string => {
        return TipoKiosko[value];
      };
    },
    tipoDeposito() {
      return (value: number): string => {
        return TipoDeposito[value];
      };
    },
    tipoEstatus() {
      return (value: number): string => {
        return EstatusServicio[value];
      };
    },
    cajerosList(): Kiosko[] {
      return CommonDataModule.Cajeros;
    },
    formasPagoList(): FormaPago[] {
      return CommonDataModule.FormasPago;
    },
    bancosList(): Banco[] {
      return CommonDataModule.Banco;
    },
    afiliacionList(): Banorte.Payworks2.Afiliacion[] {
      return CommonDataModule.Afiliacion;
    },
  },

  async created() {
    const tasks: Promise<void>[] = [];
    if (this.empresasList.length <= 1) {
      tasks.push(CommonDataModule.LoadEmpresas());
    }

    if (this.estadosList.length <= 1) {
      tasks.push(CajerosModule.LoadEstados());
    }

    if (tasks.length > 0) {
      await Promise.all(tasks);
    }
  },
  methods: {
    HideDialog() {
      Dialog.Hide();
    },
    servSelected(idServ: string) {
      return CommonDataModule.Servicios.find((e) => e.id === idServ)?.nombrePublico;
    },
    servEstatus(idServ: string) {
      return CommonDataModule.Servicios.find((e) => e.id === idServ)?.idEstatusServicio;
    },

    async municipioId() {
      await CajerosModule.LoadMunicipiosByEstado(this.idEstado);
      this.municipios = CajerosModule.municipios;
    },

    async serviciosL() {
      await CommonDataModule.LoadServicios();
      this.servicios = CommonDataModule.Servicios;
    },
    async sucursalesL() {
      await CommonDataModule.LoadSucursal();
      this.sucursal = CommonDataModule.Sucursal;
    },

    async dispositivoBilleteL() {
      await CommonDataModule.LoadDispositivoBillete();
      this.dispositivoBillete = CommonDataModule.DispositivoBillete;
    },

    async dispositivoMonedaL() {
      await CommonDataModule.LoadDispositivoMoneda();
      this.dispositivoMoneda = CommonDataModule.DispositivoMoneda;
    },

    async formaPagoL() {
      await CommonDataModule.LoadFormasPago();
      this.formaPago = CommonDataModule.FormasPago;
    },

    async bancosL() {
      await CommonDataModule.LoadBancos();
      this.bancos = CommonDataModule.Banco;
    },

    addServicioPermiso() {
      /*this.servicioPermisos.push({
        idKiosko: this.idKiosko,
        idServicio: "",
        principal: false,
        top: false,
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
        aplicadoInmediato: false,
        montoMinimo: 0,
        montoMaximo: 0,
        pagosParciales: false,
        pagosAdelantados: false,
        servicio: this.servicios2,
      });*/
    },

    addFormaPago() {
      /*this.formaPagoServicios.push({
        idKiosko: this.idKiosko,
        idServicio: "",
        idFormaPago: "",
        idBanco: "",
        idCuenta: "", //filtro con idEmprese para sacar merchantId CommonDataModule.Servicios.find(e => e.id === i.idServicio)?.idEstatusServicio
        idCuentaComision: "", //de donde sale ?
        cobroComisionPrimero: false,
        comisionIntegrada: false,
        iva: 0.16,
        monto: 0,
        montoFactura: 0,
        procesar3DS: false,
        comisionTarjeta: 0,
        comisionTarjetaFija: false,
        maximoComisionTarjeta: 0,
        tasaComisionTarjeta: 0,
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
      });*/
    },
    async idCuenta(id: string) {
      this.idEmp = CommonDataModule.Servicios.find((e) => e.id === id)?.idEmpresa ?? "";
      await CommonDataModule.LoadAfiliacion(this.idEmp);
      this.afiliacion = CommonDataModule.Afiliacion;
    },

    addDispensadores() {
      this.dispensadores = [];
      this.selected.forEach((i) => {
        this.dispensadores.push({
          id: Guid.create().toString(),
          idKiosko: this.idKiosko,
          nombre: i.nombreP,
          tipoDenominacion: 0,
          denominacion: i.denom,
          created: formatISO(Date.now()),
          updated: formatISO(Date.now()),
          minimo: 0,
          limite: 0,
          puerto: i.puerto,
          nodo: i.nodo,
        });
      });
    },

    addConfiguracion() {
      this.configuraciones.push({
        id: Guid.create().toString(),
        idKiosko: this.idKiosko,
        propiedad: "",
        valor: "",
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
      });
    },

    addServicioSucursales() {
      /*this.servicioPermisos.forEach((i) => {
        this.servicioSucursales.push({
          idKiosko: this.idKiosko,
          idServicio: i.idServicio,
          idSucursal: this.idSucursal(i.idServicio) ?? "",
          created: formatISO(Date.now()),
          updated: formatISO(Date.now()),
        });
      });*/
    },

    idSucursal(id: string) {
      this.idEmp = CommonDataModule.Servicios.find((e) => e.id === id)?.idEmpresa ?? "";
      return CommonDataModule.Sucursal.find((e) => e.idEmpresa === this.idEmp)?.id;
    },

    addServicioImagenes() {
      /*this.servicioPermisos.forEach((i) => {
        this.servicioImagenes.push({
          idKiosko: this.idKiosko,
          idServicio: i.idServicio,
          idEstatusServicio: CommonDataModule.Servicios.find((e) => e.id === i.idServicio)?.idEstatusServicio ?? 0,
          urlImagen: "",
          created: formatISO(Date.now()),
          updated: formatISO(Date.now()),
        });
      });*/
    },

    macAddress() {
      this.macAddresses.splice(0, 1);
      this.macAddresses.push({
        id: Guid.create().toString(),
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
        idKiosko: this.idKiosko,
        macAddress: "",
      });
    },

    /*removeServicioPermiso(index: number) {
      console.log(index);
      //this.servicioPermisos.splice(index, 1);
    },
    removeFormaPago(index: number) {
      console.log(index);
      //this.formaPagoServicios.splice(index, 1);
    },*/
    removeDispensadores(index: number) {
      this.dispensadores.splice(index, 1);
    },
    removeConfiguracion(index: number) {
      this.configuraciones.splice(index, 1);
    },
    removeSucursales() {
      //this.servicioSucursales = [];
    },
    removeImagenes() {
      //this.servicioImagenes = [];
    },

    async crearKiosko() {
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      //const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

      /*(this.kiosko.id = this.idKiosko),
        (this.kiosko.kioskoConfiguraciones = this.configuraciones),
        (this.kiosko.kioskoMacAddresses = this.macAddresses),
        (this.kiosko.kioskoServicioPermisos = this.servicioPermisos),
        (this.kiosko.kioskoServicioSucursales = this.servicioSucursales),
        (this.kiosko.kioskoDispensadores = this.dispensadores),
        (this.kiosko.kioskoEstatusServicioImagenes = this.servicioImagenes),
        (this.kiosko.kioskoFormaPagoServicios = this.formaPagoServicios),
        (this.kiosko.fechaConfiguracion = formatISO(Date.now())),
        (this.kiosko.created = formatISO(Date.now())),
        (this.kiosko.updated = formatISO(Date.now()));

      apiSP
        .CreateKiosko(this.kiosko)
        .then((e) => {
          console.log(e);
          if (e == true) {
            errorSnackbarModule.ShowSuccess("Nuevo kiosko registrado!");
            this.HideDialog();
          }
        })
        .catch((error) => {
          const x = error as ApiErrorResponse;
          console.log(error);
          errorSnackbarModule.Show(x.ApiError.mensajeUsuario);
        })
        .finally(() => {
          spinnerModule.Hide;
        });*/
    },
  },
});
